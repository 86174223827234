import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про компанію та послуги | EventMasters - піднесіть ваші свята
			</title>
			<meta name={"description"} content={"Комплексні послуги з планування та організації заходів, пристосовані до ваших потреб"} />
			<meta property={"og:title"} content={"Про компанію та послуги | EventMasters - піднесіть ваші свята"} />
			<meta property={"og:description"} content={"Комплексні послуги з планування та організації заходів, пристосовані до ваших потреб"} />
			<meta property={"og:image"} content={"https://fun.silvatex.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://fun.silvatex.com/img/3061290.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://fun.silvatex.com/img/3061290.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://fun.silvatex.com/img/3061290.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://fun.silvatex.com/img/3061290.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://fun.silvatex.com/img/3061290.png"} />
		</Helmet>
		<Components.Header />
		<Section
			text-align="center"
			background-color="--primary"
			color="--light"
			padding="80px 0"
			sm-padding="40px 0"
		>
			<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0 0 0">
				Про нас
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
			EventMasters пишається тим, що пропонує широкий спектр послуг, які охоплюють всі аспекти планування та організації заходів. Наша мета - забезпечити бездоганний та незабутній досвід для наших клієнтів та їхніх гостей. Від розробки концепції до реалізації, наша віддана команда гарантує, що кожна деталь буде ретельно спланована та бездоганно виконана.
			</Text>
		</Section>
		<Section padding="120px 0 130px 0" background="--color-primary" lg-padding="80px 0 90px 0" quarkly-title="Stages/Steps-2">
			<Text margin="0px 0px 20px 0px" font="--headline2" color="--light">
			Персоналізоване планування заходів
			</Text>
			<Text
				margin="0px 100px 80px 0px"
				color="#bbc4cd"
				font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
				lg-margin="0px 0 80px 0px"
				md-margin="0px 0 50px 0px"
			>
				Наші послуги з індивідуального планування заходів покликані втілити ваше бачення в життя. Ми розуміємо, що кожна подія є унікальною, і тісно співпрацюємо з вами, щоб створити індивідуальний план, який відображає ваш стиль та вподобання. Незалежно від того, чи це корпоративний захід, весілля чи приватна вечірка, наші організатори допоможуть втілити вашу мрію в реальність.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="40px 30px"
				md-grid-template-columns="1fr"
				md-grid-template-rows="auto"
				md-grid-gap="26px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					padding="0px 70px 0px 0px"
					lg-padding="0px 0 0px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 25px">
						<Text margin="0px 0px 20px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#bbc4cd">
						Концептуалізація та дизайн івенту: Ми працюємо разом з вами над розробкою унікальної концепції та дизайну вашого заходу. Наша творча команда пропонує інноваційні ідеї, щоб ваш захід запам'ятався та був візуально приголомшливим.
						<br/><br/>
   Розробка теми: Створення цілісної теми, яка відображає ваше бачення та покращує загальний досвід.
						<br/><br/>
   Декор та стилізація: Підбір і розташування елементів декору для створення красивого і цілісного вигляду.
						<br/><br/>
   Графічний дизайн: Індивідуальні запрошення, програми та вивіски, що відповідають тематиці вашого заходу.

						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					padding="0px 70px 0px 0px"
					lg-padding="0px 0 0px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 25px">
						<Text margin="0px 0px 20px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#bbc4cd">
						Підбір місця проведення та управління: Пошук ідеального місця для вашого заходу має вирішальне значення, і наша команда має великий досвід у виборі та управлінні майданчиками.
						<br/><br/>
   Пошук місця проведення заходу: Визначення майданчиків, які відповідають вашим конкретним вимогам і бюджету.
						<br/><br/>
   Переговори щодо укладення контракту: Ведення всіх переговорів для забезпечення найкращих умов та цін.
						<br/><br/>
   Координація на місці: Забезпечення безперебійної роботи в день заходу.

						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					padding="0px 70px 0px 0px"
					lg-padding="0px 0 0px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 25px">
						
						<Text margin="0px 0px 20px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#bbc4cd">
						Організація харчування та планування меню: Порадуйте своїх гостей вишуканою їжею та напоями, розробленими відповідно до тематики та вподобань вашого заходу.
						<br/><br/>
   Розробка меню: Розробка індивідуальних меню, що відповідають різноманітним смакам та дієтичним потребам.
						<br/><br/>
   Дегустаційні сесії: Організація дегустацій, щоб переконатися, що ви задоволені вибором.
						<br/><br/>
   Презентація їжі: Координація роботи з постачальниками, щоб забезпечити відповідність презентації їжі темі заходу.

						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});